import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const Volunteer = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Volunteer"
          description="Join SWA to volunteer
                    in any community projects."
        />
        <div className="max-w-2xl px-4 pt-12 mx-auto sm:px-6 lg:max-w-4xl lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-swa-1 sm:text-4xl">
            Volunteer with SWA
          </h2>
        </div>
        <section className="overflow-hidden bg-white">
          <div className="relative px-4 pt-20 pb-1 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute left-0 transform -translate-y-24 top-full translate-x-80 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
              />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <img
                  className="w-64 h-64 rounded-full xl:h-80 xl:w-80"
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1662371807/participatePage/cw8zi9bymekvwfz7xuoa.webp"
                  alt=""
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 text-indigo-200 transform -translate-x-8 -translate-y-24 opacity-50 h-36 w-36"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-2xl font-medium leading-9 text-gray-900">
                    <p>
                      When I was approached to help in the event, I wondered
                      what I can contribute. However, I readily agreed as I
                      believe in nothing ventured nothing gained. It was a
                      meaningful session when I see the smiles on the residents
                      from the activities conducted. I realised that simply
                      being there with a bright smile and having genuine
                      conversations could bring so much to my soul and make
                      someone else’s day.
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <img
                          className="w-12 h-12 rounded-full"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1662371807/participatePage/cw8zi9bymekvwfz7xuoa.webp"
                          alt=""
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">
                          Shirley Wong
                        </div>
                        <div className="text-base font-medium text-indigo-600">
                          Managing partner of TNF ventures
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
        <section className="overflow-hidden bg-white">
          <div className="relative px-4 pt-20 pb-1 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute left-0 transform -translate-y-24 top-full translate-x-80 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
              />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 text-indigo-200 transform -translate-x-8 -translate-y-24 opacity-50 h-36 w-36"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-2xl font-medium leading-9 text-gray-900">
                    <p>
                      I am very passionate about singing and interested in
                      composing songs. I am so happy to sing songs to the
                      residents in Ren Ci.
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <img
                          className="w-12 h-12 rounded-full"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1662372180/participatePage/rrsrcxsrykmoufvxmwoe.webp"
                          alt=""
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">
                          Cecilia Thean
                        </div>
                        {/* <div className="text-base font-medium text-indigo-600">
                          Managing partner of TNF ventures
                        </div> */}
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
              <div className="hidden lg:block lg:flex-shrink-0">
                <img
                  className="w-64 h-64 rounded-full xl:h-80 xl:w-80"
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1662372180/participatePage/rrsrcxsrykmoufvxmwoe.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="overflow-hidden bg-white">
          <div className="relative px-4 pt-20 pb-1 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute left-0 transform -translate-y-24 top-full translate-x-80 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
              />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <img
                  className="w-64 h-64 rounded-full xl:h-80 xl:w-80"
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1662372401/participatePage/vucoz6m5jumbdrqhlwyl.webp"
                  alt=""
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 text-indigo-200 transform -translate-x-8 -translate-y-24 opacity-50 h-36 w-36"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-2xl font-medium leading-9 text-gray-900">
                    <p>
                      It was heart touching and an amazing experience to
                      volunteer for Renci. Many old folks are lonely and
                      grateful for some company. Glad the social staff and
                      nurses are very helpful in guiding volunteers. It will be
                      good to reach out to make a small difference to them.
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <img
                          className="w-12 h-12 rounded-full"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1662372401/participatePage/vucoz6m5jumbdrqhlwyl.webp"
                          alt=""
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">
                          Karen Tan
                        </div>
                        <div className="text-base font-medium text-indigo-600">
                          Head of private banking - Wealth Management VP bank
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="overflow-hidden bg-white">
          <div className="relative px-4 pt-20 pb-1 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute left-0 transform -translate-y-24 top-full translate-x-80 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
              />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 text-indigo-200 transform -translate-x-8 -translate-y-24 opacity-50 h-36 w-36"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-2xl font-medium leading-9 text-gray-900">
                    <p>
                      I have been a volunteer with SWA for the past few years.
                      It was a very touching and heartfelt experience when I was
                      able to meet and talk to some of the seniors at Ren Ci
                      Hospital. Since the pandemic, I continued to volunteer for
                      SWA and Ren Ci Hospital activities for the seniors over
                      Zoom and was able to bring some joy through songs.
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <img
                          className="w-12 h-12 rounded-full"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1662372559/participatePage/yjiagll4vhaip3jqqmho.webp"
                          alt=""
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">
                          Carmen
                        </div>
                        <div className="text-base font-medium text-indigo-600">
                          Business Development in telco industry
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
              <div className="hidden lg:block lg:flex-shrink-0">
                <img
                  className="w-64 h-64 rounded-full xl:h-80 xl:w-80"
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1662372559/participatePage/yjiagll4vhaip3jqqmho.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-col max-w-4xl px-2 pb-20 mx-auto lg:space-x-6 lg:flex-row sm:w-4/5">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div className="relative sm:py-16 lg:py-0">
              <div
                aria-hidden="true"
                className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
              >
                <div className="absolute inset-y-0 w-full right-1/2 bg-gray-50 rounded-r-3xl lg:right-72" />
                <svg
                  className="absolute -ml-3 top-8 left-1/2 lg:-right-8 lg:left-auto lg:top-12"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={392}
                    fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                  />
                </svg>
              </div>
              <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                {/* Testimonial card*/}
                <div className="relative pt-64 pb-10 overflow-hidden shadow-xl rounded-2xl">
                  <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636787868/participatePage/kq7btyageqcoudjcqxhe.jpg"
                    alt=""
                  />
                  {/* <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" /> */}
                  {/* <div className="absolute inset-0 opacity-50 bg-gradient-to-t from-indigo-600 via-indigo-600" /> */}
                  <div className="relative px-8">
                    {/* <blockquote className="mt-8">
                        <div className="relative text-lg font-medium text-white md:flex-grow">
                          <svg
                            className="absolute top-0 left-0 w-8 h-8 text-indigo-400 transform -translate-x-3 -translate-y-2"
                            fill="currentColor"
                            viewBox="0 0 32 32"
                            aria-hidden="true"
                          >
                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                          </svg>
                          <p className="relative">
                            Tincidunt integer commodo, cursus etiam aliquam
                            neque, et. Consectetur pretium in volutpat, diam.
                            Montes, magna cursus nulla feugiat dignissim id
                            lobortis amet.
                          </p>
                        </div>

                        <footer className="mt-4">
                          <p className="text-base font-semibold text-indigo-200">
                            Sarah Williams, CEO at Workcation
                          </p>
                        </footer>
                      </blockquote> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
              {/* Content area */}
              <div className="pt-12 sm:pt-16 lg:pt-20">
                <div className="my-5 space-y-6 text-xl leading-8 text-gray-500">
                  <p>
                    SWA welcomes donations and support from individuals and
                    corporate member volunteers for supporting our charity
                    efforts. You can be part of us and contribute your time or
                    expertise to help us with administrative matters and
                    management of our charity programmes &amp; projects.
                  </p>
                  <p>
                    SWA encourages more people especially the young to volunteer
                    in any community projects. The personal reward you gain will
                    not only make you happy, but it also significantly and
                    positively impact the lives of those you have helped. You
                    learn, grow and widen your horizons. These only come when
                    you are sincere, willing to serve and open to others
                  </p>
                  <Link to="/forms/volunteer">
                    <button className="mt-3 btn-dark">
                      Apply to be a volunteer
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Volunteer;
